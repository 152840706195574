<template>
    <div>
        <Header :id="id"></Header>
        <div class="nbanner">
          <img src="@/assets/images/banner.png" alt="">
        </div>
        <div class="nnews">
          <div class="nnews2">
            <div class="ntitle">
                <a-breadcrumb separator=">">
                    <a-breadcrumb-item>
                        <router-link :to="{ name: 'Exhibi', params: { id: id } }">{{ $t('home') }}</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item href="/news">
                        <router-link :to="{ name: 'News', params: { id: id } }">{{ $t('news') }}</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        {{ $t('news.detail') }}
                    </a-breadcrumb-item>
                </a-breadcrumb>
            </div>
          </div>
          <div class="nnews3">
            <div class="ninfo">
                <div class="ititle">{{newslist.title}}</div>
                <div class="idata">{{newslist.publishedAt}}  |  {{newslist.fromPlat}} </div>
                <div class="ilist" v-html="newslist.content">
                   {{newslist.content}}
                </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>

import Header from "./components/Header";
import Footer from "../components/Footer";

export default {
  name: "News",
  components: {
    Footer,
    Header,
  },
  data () {
    return {
      id: this.$route.params.id,
      newsId: this.$route.params.newsId,
      newslist: []
    }
  },
  mounted () {
    this.getList()
  },
   methods: {
     // 获取新闻详情
    getList () {
      this.$common.fetchList('/article/detail/'+this.newsId, {}).then(data => {
        if (data.code === 200) {
          const result = data.data
          this.newslist = result
          console.log(this.newslist)
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    }
   }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/newsdetail.less';
</style>